import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Apis {
  configUrl: string = environment.apiUrl + 'admin/v1/super/admin/';
  adminId: string = sessionStorage.getItem('adminId');
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }


  getWorkspaceDocuments(workspaceId, collaboratorId) {
    let url = this.configUrl + 'workspace/collaborator/' + collaboratorId + '/documents?workspaceId=' + workspaceId;
    return this.http.get(url)
  }
  addNote(id: string, body) {
    // {
    //   "refId": "97b5c248-3929-415b-b82a-3f5c3ac97894",
    //     "note": "asd",
    //       "reason": "User message",
    //         "type": "userManagement",
    //           "changedBy": "1",
    //             "doneBy": "xyz"
    // }
    const url = this.configUrl + 'internal/notes?isUpdated=true';

    console.log('Sending to updateNote:', body);
    return this.http.put(url, body);
  }

  getNotes(id: string) {
    const url = this.configUrl + `internal/notes?refId=${id}`;
    console.log('Requesting URL:', url);
    return this.http.get(url)
  }
}
