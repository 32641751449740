<form [formGroup]="form" class="container">
    <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <mat-label style="color: black;font-weight: 500;">{{ controller['label'] ? controller['label'] :
            ''}}</mat-label>
        <p (click)="clearAll()" *ngIf="form.get(controller['name']).value.length > 0">
            Clear All
            <!-- <mat-icon>close</mat-icon> -->
        </p>
    </div>
    <p-treeSelect [options]="controller['data']" [formControlName]="controller['name']" placeholder="Select items"
        selectionMode="checkbox" (onNodeSelect)="onNodeSelect($event)" [filter]="true" filterPlaceholder="Search..."
        closeOnNodeSelect="false" (onNodeUnselect)="onNodeUnselect($event)">
        <ng-template let-node pTemplate="default">
            {{ node.label }} <!-- Displays only the label without the count -->
        </ng-template>

    </p-treeSelect>
    <!-- <div class="tree-select-custom-toolbar">
        <button pButton type="button" icon="pi pi-times" (click)="clearAll()"></button>
    </div> -->

</form>

<!-- <p>Selected Value: {{ form.get('selectedNode')?.value | json }}</p> -->