<div *ngIf="options.length === 0">Loading...</div>
 <!-- *ngIf="options.length > 0" -->
<div class="container" *ngIf="options.length > 0">

    <!-- <mat-form-field *ngIf="form && controller" [formGroup]="form">
        <mat-label>{{controller['label']}}</mat-label>
        <mat-select [formControlName]="controller['name']" [multiple]="multiple">
            <mat-option *ngFor="let option of controller['options']"
                [value]="option['value']">{{option['name']}}</mat-option>
        </mat-select>
    </mat-form-field> -->
    <form *ngIf="form && controller" [formGroup]="form">
        <ng-select [items]="options" [placeholder]="controller['label']" [multiple]="multiple"
            [formControlName]="controller['name']" [searchable]="true" bindLabel="name" bindValue="value" (change)="onChange($event)">
        </ng-select>
    </form>
    <!-- <div *ngIf="this.form.get(controller['name']).invalid && (this.form.get(controller['name']).dirty || this.form.get(controller['name']).touched)"
        class="error">
        <div *ngIf="this.form.get(controller['name']).hasError('required')">
            <mat-error>Field is <strong>required</strong></mat-error>
        </div>
    </div> -->


</div>