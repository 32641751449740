import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {
  @Input() form : FormGroup;
  @Input() controller : any;
  @Input() changed = false;
  ngOnInit(){    
    this.form.get(this.controller['name']).valueChanges.subscribe((res)=>{
    })
  }
}
