<div class="container">
    <mat-label style="color: black;padding-bottom: 1%; font-weight: 500;">Internal Notes
        
    </mat-label>
    <div class="add-note-container">
        <mat-form-field appearance="outline" class="new-note-input">
            <mat-label>New Note</mat-label>
            <textarea matInput [(ngModel)]="newNote" placeholder="Write a note..."></textarea>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addNote()" [disabled]="!newNote">
            Add Note
        </button>
    </div>

    <!-- Notes List -->
    <div class="notes-list">
        <mat-card *ngFor="let note of notesArray" class="note-block">
            <mat-card-title>{{ note['note'] }}</mat-card-title>
            <mat-card-subtitle>Created By {{ note['doneBy'] }}</mat-card-subtitle>
            <mat-card-subtitle>{{ currentUTCDate(note['createdAt']) }}</mat-card-subtitle>
        </mat-card>
    </div>
</div>