import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Controller } from 'app/interfaces/controller';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() form : FormGroup;
  @Input() controller : Controller;
  @Input() changed: false;
  @Input() readonly;
  @Input() type = "string"
  @Input() maxlength : number;
  matcher = new MyErrorStateMatcher();

  constructor(){
    
  }
  ngOnInit(){
    
    
    // this.form.get(this.controller['name']).valueChanges.subscribe((res)=>{
    //   console.log(this.form.get(this.controller['name']));
      
    // })
  }
}
