import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-generic-actions',
  templateUrl: './generic-actions.component.html',
  styleUrls: ['./generic-actions.component.scss']
})
export class GenericActionsComponent {
  @Output() clearTableEvent = new EventEmitter<void>()
  @Input() actionName: string = 'Add';
  @Input()showAll : boolean ;
  @Input() isLive : boolean;
  @Input() liveOrAll : boolean;
  @Input() activeOrAll : boolean = false;
  @Output() customClick = new EventEmitter<void>();

  @Output() showData : EventEmitter<string> = new EventEmitter();
  @Output() addEvent: EventEmitter<string> = new EventEmitter();
  @Output() exportEvent: EventEmitter<string> = new EventEmitter();
  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() liveAction : EventEmitter<boolean> = new EventEmitter();
  @Input() table: any; // Accepts table reference
  searchTerm: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    // Disable ripple effect on initialization
    this.disableRipple();
  }
  handleClick(event: MouseEvent) {
    // Emit click event to parent
    this.customClick.emit();
  }
  ngOnInit(){
    // this.searchTerm = sessionStorage.getItem('viewPetAppointmentId');
    // this.onSearchInput();
  }
  onClear() {
    this.clearTableEvent.emit(); // Emit reset event
}
  emitClearEvent() {
    // Emit the custom click event for the parent to handle
    this.customClick.emit();
  }
  showLiveOrActive(){
    this.liveAction.emit(!this.isLive)

  }

  onSearchInput() {
    this.filterEvent.emit(this.searchTerm);
  }
  

  showDataClick(){
    this.showData.emit();
  }

  
  disableRipple() {
    const button = this.elementRef.nativeElement.querySelector('button.mat-button');
    if (button) {
      this.renderer.setAttribute(button, 'disableRipple', 'true');
    }
  }

  filter() {

  }

  add() {
    this.addEvent.emit("add")
  }

  export() {
    this.exportEvent.emit();
  }
}