import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-value-dropdown',
  templateUrl: './multi-value-dropdown.component.html',
  styleUrls: ['./multi-value-dropdown.component.scss']
})
export class MultiValueDropdownComponent {
  @Input() form : FormGroup;
  @Input() controller : any;
  ngOnInit(){
    
    
  }
}
