import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent {
  @Input() form : FormGroup;
  @Input() controller;
 

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    // this.form = this.fb.group({
    //   selectedNodes: [[]], // Initialize the form control
    // });
  }

  onNodeSelect(event: any) {
    // if (event.node.children) {
    //   this.addChildrenToSelection(event.node.children);
    // }
    // this.removeParentFromSelection(event.node); // Remove the parent itself if selected
  }

  // Handle node unselection
  onNodeUnselect(event: any) {
    // if (event.node.children) {
    //   this.removeChildrenFromSelection(event.node.children);
    // }
  }
  clearAll() {
    console.log("dsf");
    
    this.form.get(this.controller['name'])?.setValue([]);
  }

  // Add all children to selection recursively
  
}
