import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss']
})
export class MultiCheckboxComponent {
  @Input() form : FormGroup;
  @Input() controller : any;
  allSelected = false; // Tracks the "All" checkbox state
  constructor(private fb : FormBuilder){

  }

  ngOnInit(){
    let checkBoxes = this.form.get(this.controller['name']) as FormArray;
    this.controller['options'].forEach((el)=>{
      checkBoxes.push(this.fb.control(false))
    })
   
  }

  get checkboxes(): FormArray {
    return this.form.get(this.controller['name']) as FormArray;
  }

  toggleAll(): void {
    this.allSelected = !this.allSelected; // Toggle "All" state
    console.log(this.checkboxes);
    
    this.checkboxes.controls.forEach((control) => control.setValue(this.allSelected));
  }

  onToggleChange(): void {
    this.allSelected = this.checkboxes.controls.every((control) => control.value);
  }


}
