import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-images-upload',
  templateUrl: './images-upload.component.html',
  styleUrls: ['./images-upload.component.scss']
})
export class ImagesUploadComponent {
  @Input() form: FormGroup;
  @Input() controller: any;
  // imageForm: FormGroup;
  uploadedImages: any[] = [];
  deletedImages: any[] = [];
  @Output() deletedImagesEvent = new EventEmitter<any>();
  @Output() uploadedImagesEvent = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    if (this.imageFormArray.value) {
      this.imageFormArray.value.forEach((img) => {
        // if (!img.includes('profile_image.jpg')) {
          this.uploadedImages.push(img);
        // }
      })
    }
    // this.imageForm = this.formBuilder.group({
    //   images: this.formBuilder.array([])
    // });
  }

  get imageFormArray(): FormArray {
    return this.form.get(this.controller['name']) as FormArray;
  }

  onFileSelected(event): void {
    const files = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type.startsWith('image')) {
          const reader = new FileReader();
          reader.onload = () => {
            // files[i]['objectURL']['changingThisBreaksApplicationSecurity']
            this.imageFormArray.push(this.formBuilder.control(files[i]));
            this.uploadedImages.push(reader.result)
            // this.imageFormArray.push(this.formBuilder.group({
            //   file: files[i],
            //   url: reader.result
            // }));
          };
          reader.readAsDataURL(files[i]);
        }
        this.uploadedImagesEvent.emit(files);
      }
    }
  }

  removeImage(index: number, image): void {
    let ix
    if (this.uploadedImages && -1 !== (ix = this.uploadedImages.indexOf(image))) {
      this.uploadedImages.splice(ix, 1)

    }
    this.imageFormArray.removeAt(index);
    this.deletedImages.push(image);
    this.deletedImagesEvent.emit(image);
  }

  onSubmit(): void {
    const formData = new FormData();
    const images = this.imageFormArray.value;
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i].file);
    }

  }
}
