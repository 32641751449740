import { Component, Input } from '@angular/core';
import { Apis } from 'app/services/apis';
import { LoaderService } from 'app/services/loader.service';
import moment from 'moment';

@Component({
  selector: 'app-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss']
})
export class InternalNotesComponent {
  @Input() type: string = 'abc';
  @Input() id: string;
  notesArray: Array<any>;
  newNote: string = '';

  constructor(
    private api: Apis,
    private loadr : LoaderService
  ) {

  }

  ngOnInit() {
    this.getNotes("abc");
  }

  addNote() {
    let body = {
      "refId": this.id,
      "note": this.newNote,
      "reason": "User message",
      "type": this.type,
      "changedBy": sessionStorage.getItem('adminId'),//id
      "doneBy": sessionStorage.getItem('adminName') + " " + sessionStorage.getItem('adminSurname')//name
      
    }
    if (this.newNote.trim()) {
      this.loadr.showLoader();
      this.api.addNote(this.id, body).subscribe((res) => {
        this.loadr.hideLoader();
        this.notesArray.push({ note: this.newNote, createdAt: new Date().toISOString(),changedBy: sessionStorage.getItem('adminId'),  doneBy: sessionStorage.getItem('adminName') + " " + sessionStorage.getItem('adminSurname')});
        this.newNote = ''; // Clear the input
      })
    } else {
      alert('Note cannot be empty!');
    }
  }


  getNotes(type) {
    this.loadr.showLoader();
    this.api.getNotes(this.id).subscribe((res: Array<any>) => {
      this.loadr.hideLoader();
      this.notesArray = res;
    })
    // this.notesArray = [
    //   {
    //     "id": "080b875f-aa43-4c95-87c2-9ec2ef820433",
    //     "refId": "073fd20f-75dc-4542-9419-6f3894335ebc",
    //     "note": "yyy",
    //     "reason": "User message",
    //     "type": "collaborator",
    //     "changedBy": "1",
    //     "doneBy": "yyy",
    //     "createdAt": 1733898205000
    //   },
    //   {
    //     "id": "080b875f-aa43-4c95-87c2-9ec2ef820433",
    //     "refId": "073fd20f-75dc-4542-9419-6f3894335ebc",
    //     "note": "xyz",
    //     "reason": "User message",
    //     "type": "collaborator",
    //     "changedBy": "1",
    //     "doneBy": "xyz",
    //     "createdAt": 1733898205000
    //   },
    //   {
    //     "id": "080b875f-aa43-4c95-87c2-9ec2ef820433",
    //     "refId": "073fd20f-75dc-4542-9419-6f3894335ebc",
    //     "note": "xxx",
    //     "reason": "User message",
    //     "type": "collaborator",
    //     "changedBy": "1",
    //     "doneBy": "xxx",
    //     "createdAt": 1733898205000
    //   }, 
    //   {
    //     "id": "080b875f-aa43-4c95-87c2-9ec2ef820433",
    //     "refId": "073fd20f-75dc-4542-9419-6f3894335ebc",
    //     "note": "xyz",
    //     "reason": "User message",
    //     "type": "collaborator",
    //     "changedBy": "1",
    //     "doneBy": "xyz",
    //     "createdAt": 1733898205000
    //   }
    // ]
    //api
  }

  currentUTCDate(date): string {
    return moment.utc(date).local().format("DD-MMM-YYYY hh:mm A");
  }

}
