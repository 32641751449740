<form *ngIf="form && controller" [formGroup]="form">
    <mat-label style="color: black;padding-bottom: 1%; font-weight: 700;">{{controller['label']}}

    </mat-label>
    <div class="checkbox-container">
        <mat-checkbox [checked]="allSelected" (change)="toggleAll()">
            All
        </mat-checkbox>
        <div [formArrayName]="controller['name']" class="checkbox-container">
            <div *ngFor="let item of controller['options']; let i = index">
                <mat-checkbox [formControlName]="i" (change)="onToggleChange()">{{ item }}</mat-checkbox>
            </div>
        </div>
    </div>
    
</form>