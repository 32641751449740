<div *ngIf="form && controller" class="container">
    <div class="upload-file">
        <mat-label style="color: black;padding-bottom: 1%; font-weight: 700;">{{controller['label']}}
            <div style="color: red;" *ngIf="error">
                {{error}}
            </div>
        </mat-label>
        <!-- <label>{{controller['label']}}</label> -->
        <button mat-raised-button (click)="onClick($event)" *ngIf="files.length == 0 && !isView"
            style="width: fit-content;align-items: center;" type="button">
            {{chooseLabel}}
        </button>
        <input #fileUpload type="file" class="input_fileupload--hidden" (input)="onInput($event)"
            (change)="onFileSelected($event)" [(ngModel)]="inputFileName" [accept]="accept">

        <div *ngIf="files.length == 0 && isView">No files Selected</div>

    </div>

    <!--         [formControlName]="controller['name']"
 -->
    <div *ngFor="let f of files" class="images" style="align-items: center;">
        <!-- f['objectURL']['changingThisBreaksApplicationSecurity'] -->
        <img [src]="f" alt="img" *ngIf="accept == 'image/*'" />
        <video controls [src]="f" *ngIf="accept == 'video/*'">
            Your browser does not support the video tag.
        </video>
        <button mat-mini-fab color="warn" (click)="removeFile(event,f)" class="icon-remove" *ngIf="!isView">
            <mat-icon>close</mat-icon>
        </button>
        <!-- <mat-icon (click)="removeFile(event,f)" class="icon-remove">close</mat-icon> -->
        <!-- <button mat-icon-button color="primary" [attr.title]="deleteButtonLabel?deleteButtonLabel:null"
            (click)="removeFile(event,f)">
            <mat-icon>close</mat-icon>
        </button> -->
    </div>
    <div class="btn-wrapper" style="text-align: center;">
        <button mat-stroked-button *ngIf="showButton && files.length > 0" (click)="onButtonClick()">Upload</button>
    </div>
</div>