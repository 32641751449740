import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Controller } from 'app/interfaces/controller';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
  @Input() form: FormGroup;
  @Input() controller: Controller;
  @Input() disabled: boolean = false;
  @Input() isView: boolean;
  @Input() value: any;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder) { }

  onToggleChange(event) {
    this.valueChange.emit(event.checked);
  }

  ngOnInit() {

    // this.form.get(this.controller['name'])?.valueChanges.subscribe(value => {
    //   this.valueChange.emit(value);
    // });
  }

}
