import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericActionsComponent } from './generic-actions/generic-actions.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { InternalNotesComponent } from './internal-notes/internal-notes.component';
import {MatListModule} from '@angular/material/list';



@NgModule({
  declarations: [
    GenericActionsComponent,
    InternalNotesComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatListModule
  ],
  exports : [
    GenericActionsComponent,
    InternalNotesComponent
  ]
})
export class CommonComponentsModule { }
