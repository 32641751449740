import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ControllersModule } from './components/controllers/controllers.module';
import {HttpClientModule } from '@angular/common/http';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet,CommonModule, HttpClientModule,ControllersModule],
})
export class AppComponent
{
    /**
     * Constructor
     */
    observableData$: Observable<any> = of('Hello World');
    constructor(public loaderService: LoaderService) {
        
      }
    
      
    isLoader: boolean = false;
    ngOnInit(){
        // console.log(this.loaderService.isLoading);
        this.loaderService.getLoaderVal().subscribe((res)=> {
            this.isLoader = res; 
        })
    }
}
