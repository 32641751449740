import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent {
  @Output() onFileSelect: EventEmitter<string> = new EventEmitter();
  @Output() onRemove : EventEmitter<number> = new EventEmitter();
  @Input() form: FormArray;
  @Input() controller: any;
  myFiles:string [] = [];
  sMsg:string = '';
  files: File[] = []
  constructor(
    private sanitizer: DomSanitizer,
    private fb : FormBuilder
    ){

  }
  ngOnInit () { 
    this.form.value.forEach((file)=>{
      this.files.push(file)
    })
    
   }

  getFileDetails (e) {
    //console.log (e.target.files);
    for (var i = 0; i < e.target.files.length; i++) { 
      this.myFiles.push(e.target.files[i]);

      // this.form.push(e.target.files[i])

    }
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      //if(!this.isFileSelected(file)){
      if (this.validate(file)) {
        //      if(this.isImage(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
        //      }
        // if (!this.isMultiple()) {
        //   this.files = []
        // }
        this.files.push(files[i]['objectURL']['changingThisBreaksApplicationSecurity']);
        //  }
        let formArray = this.form as FormArray;

        formArray.push(
          this.fb.control(file)
        )
        this.onFileSelect.emit(this.controller['name']);
      }
      //}
  }}
  validate(file: File) {
    for (const f of this.files) {
      if (f.name === file.name
        && f.lastModified === file.lastModified
        && f.size === f.size
        && f.type === f.type
      ) {
        return false
      }
    }
    return true
  }

  uploadFiles () {
    const frmData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) { 
      frmData.append("fileUpload", this.myFiles[i]);
    }
  }
  removeFile(event, file,i) {
    let ix
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1)
      // this.clearInputElement()
      this.onRemove.emit(i)
    }
  }
}
